import { ad_1, ad_2, all_products } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import BrandCard from "components/Cards/BrandCard";
import Pagination from "components/Pagination";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CgSortAz } from "react-icons/cg";

const BrandsList = () => {
  const { slug } = useParams();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [brandCategories, setBrandCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [brandCategory, setbrandCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [brand, setBrand] = useState("");
  const scrollRef = useRef();
  const navigate = useNavigate();

  const fetchBrands = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsPagewise(page);
      if (response.data) {
        setBrands(response.data.results);
        // setTotalPages(Math.ceil(response.data.count / 12));
        const totalFetched = page * 12;
        setHasMore(totalFetched < response.data.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProductCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      console.log(response);
      if (response?.data) {
        setBrandCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(brandCategories);

  const fetchProductTypes = async (categoryId) => {
    try {
      const response = await authActions.fetchProductTypes(categoryId);
      console.log(response);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductSubTypes = async (typeId) => {
    try {
      const response = await authActions.fetchProductSubTypes(typeId);
      console.log(response);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);

    const category = searchParams.get("product_category");
    const type = searchParams.get("productType");
    const subType = searchParams.get("productSubType");
    const sort = searchParams.get("sort_by");
    const brandParam = searchParams.get("brand");

    // Set state based on the URL parameters
    if (category) setbrandCategory(category);
    if (type) setProductType(type);
    if (subType) setProductSubType(subType);
    if (sort) setSortBy(sort);
    if (brandParam) setBrand(brandParam);
  }, []);


  const onChangeFilters = (value, type) => {
    // Initialize an object to hold the filters
    const filterParams = {
      category: type === "category" ? value : brandCategory,
      product_type: type === "product_type" ? value : productType,
      product_sub_type: type === "product_sub_type" ? value : productSubType,
    };

    // Remove filters with null or empty values
    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val) // Only include filters with non-null, non-empty values
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    // Fetch product types if category is selected
    if (type === "category") {
      setbrandCategory(value); // Update state for category
      setProductType(""); // Reset product type
      setProductSubType(""); // Reset product sub-type
      fetchProductTypes(value); // Pass the category ID to fetch product types
    }

    // Fetch product subtypes if product type is selected
    if (type === "product_type") {
      setProductType(value); // Update state for product type
      setProductSubType(""); // Reset product sub-type
      fetchProductSubTypes(value); // Pass the product type ID to fetch subtypes
    }

    // Update product sub-type state if sub-type is selected
    if (type === "product_sub_type") {
      setProductSubType(value);
    }

    // Navigate to the updated URL
    navigate(`/filtered-products?${queryString}`);
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);


  useEffect(() => {
    fetchBrands(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && !loading) {
          setCurrentPage(prevPage => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        observer.unobserve(scrollRef.current);
      }
    };
  }, [hasMore, loading]);

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container className="mt-5 pt-5">
      <section className="d-flex flex-column alignt-items-center">
        <div className="d-flex">
          <div
            className="card me-2 mb-2"
            style={{
              width: "150px",
              border: "1px solid #BDC1C6",
              borderRadius: "5px",
              cursor: "pointer",
              padding: '0px'
              // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          // onClick={() => onChangeFilters(brandCat?.slug, "brandCategory")}
          >
            <img
              src={all_products}
              alt='all products'
              className="card-img-top"
              style={{
                width: '100%',
                height: "100px",
                objectFit: "cover",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                margin: '0px'
              }}
            />
            <div className="card-body text-center p-1">
              <h6 className="card-title text-gray-700 mb-0">All Products</h6>
            </div>
          </div>
          {Array.isArray(brandCategories) && brandCategories?.map((brandCat, index) => (
            <div
              key={index}
              className="card me-2 mb-2"
              style={{
                width: "150px",
                border: "1px solid #BDC1C6",
                borderRadius: "5px",
                cursor: "pointer",
                padding: '0px'
                // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                setbrandCategory(brandCat.id);
                onChangeFilters(brandCat.id, "category");
              }}
            >
              <img
                src={brandCat?.image || all_products} // Replace with the correct image path
                alt={brandCat?.title}
                className="card-img-top"
                style={{
                  width: '100%',
                  height: "100px",
                  objectFit: "cover",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  margin: '0px'
                }}
              />
              <div className="card-body text-center p-1">
                <h6 className="card-title text-gray-700 mb-0">{brandCat.title}</h6>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            {(productType && productType !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productType}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "brandCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productType")}
                value={productType && productType !== "null" ? productType : ""}
              >
                <option value="" disabled>
                  Product Type
                </option>
                {productTypes.map((prodType, index) => {
                  return (
                    <option key={index + 1} value={prodType.value}>{prodType.label}</option>
                  );
                })}
              </Form.Select>
            }

            {(productSubType && productSubType !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productSubType}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "brandCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productType")}
                value={productSubType && productSubType !== "null" ? productSubType : ""}
              >
                <option value="" disabled>
                  Product Sub Type
                </option>
                {productSubTypes?.map((prodSubType, index) => {
                  return (
                    <option key={index + 1} value={prodSubType.value}>{prodSubType.label}</option>
                  );
                })}
              </Form.Select>
            }
            {(brand && brand !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {brand}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "brandCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productType")}
                value={brand && brand !== "null" ? brand : ""}
              >
                <option value="" disabled>
                  Brand
                </option>
                {brands?.map((b, index) => {
                  return (
                    <option key={index + 1} value={b.value}>{b.label}</option>
                  );
                })}
              </Form.Select>
            }
          </div>

          <div>
            <div className="custom-select-wrapper">
              <Form.Select
                className="bg-transparent text-gray-700 cursor-pointer custom-select"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                value={sortBy && sortBy !== "null" ? sortBy : ""}
              >
                <option value="" disabled>Sort By</option>
                <option value="created_at">Featured (New to Old)</option>
                <option value="-created_at">Featured (Old to New)</option>
              </Form.Select>
              <CgSortAz size={24} className="custom-dropdown-icon" />
            </div>
          </div>
        </div>
        <Row>
          <Col lg={8} xl={9}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : brands?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {brands?.map((brand, index) => {
                  return (
                    <BrandCard
                      id={brand.user}
                      slug={brand.slug}
                      companyName={brand.company_name}
                      image={brand?.image}
                      // image={brand.user_data?.profile_picture}
                      brandCategories={brand?.brand_category_data}
                      warranty={brand?.warranty}
                      rating={brand?.rating}
                      averagePricing={brand?.average_pricing}
                    />
                  );
                })}
                <div
                  ref={scrollRef}
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 50 }}
                >
                  {loading && <Spinner />}
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 160 }}
              >
                No brands available
              </div>
            )}
          </Col>
          <Col lg={4} xl={3}>
            <div className="my-4 position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold">
                  AD
                </Button>
              </div>
            </div>
            <div className="position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold">
                  AD
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BrandsList;
