import {
  wcu_n1,
  wcu_n2,
  wcu_n3,
  wcu_n4,
  wcu_n5,
  wcu_n6,
  wcu_n7,
  wcu_n8,
  wcu_n9,
} from "assets";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const WhyChooseUs = () => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [hovered, setHovered] = useState(null);


  const reasonsToChoose = [
    { img: wcu_n1, title: "Pre-aligned and Ready to Execute Designs", description: "Speed up projects with pre-designed inspirations from top designers, ensuring fast execution without sacrificing quality." },
    { img: wcu_n2, title: "Tailored Solution for Every Space", description: "Access multiple design inspirations for all layouts, with customizable options to match your preferences and style." },
    { img: wcu_n3, title: "Access to Premium and Exclusive Designs", description: "Unlock exclusive, high-quality designs and products crafted by top professionals for standout, luxurious interiors." },
    { img: wcu_n4, title: "Endless Design Options", description: "Explore a wide range of design styles and budgets, with options to suit any taste or project." },
    { img: wcu_n5, title: "Trusted Network of Professionals", description: "Work with top-quality professionals vetted by Interiokeys for reliable and high-standard services." },
    { img: wcu_n6, title: "Multilayer Warranty System", description: "Enjoy an extra 2-year warranty above the standard warranties for our brand partners for added peace of mind." },
    { img: wcu_n7, title: "Transparent Pricing and Cost Control", description: "Clear pricing with no hidden fees, real-tme cost updates and full control of your budget throughout the project." },
    { img: wcu_n8, title: "Convenient and Time-Saving", description: "Simplify the design process with ready-to execute options and easy collaboration, saving time and reducing delays." },
    { img: wcu_n9, title: "Genuine Rating System", description: "Get transparent ratings from clients and professionals, along with Interiokeys performance ratings to showcase work quality." },
  ];

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <section className="d-flex flex-column align-items-center">
        <div className="h1 inter-600 text-gray-900 text-center mb-lg-2 mb-4 mt-lg-5 mt-4">Streamlining an Unorganized Industry</div>
        <div className="body-title inter-400 text-gray-600 text-center">By connecting architects, builders, product brands, and homeowners on one platform, we simplify collaboration,<br /> product discovery, and decision-making, enabling smoother project execution and more inspiring spaces.</div>
        <div className="d-flex justify-content-center flex-wrap p-5">
          {reasonsToChoose.map(({ img, title, description }, index) => (
            <Col key={index} xs={12} md={6} lg={4} className="p-2">
              <div
                className="p-3 text-start"
                style={{ border: hovered === index ? "1px solid #BDC1C6" : "1px solid  #E8EAED", transition: "border-color 0.3s ease", borderRadius: "6px" }}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
              >
              <img
                src={img}
                alt={title}
                style={{ width: "60px" }}
              />
              <div
                className="h4 inter-600 mt-3"
                style={{
                  color: hovered === index ? "#C53D3D" : "#1A1C1F",
                }}

              >
                {title}
              </div>
              <p className="body-text1 inter-400 text-gray-700">
                {description}
              </p>
            </div>
            </Col>
          ))}
      </div>
    </section>
    </Container >
  );
};

export default WhyChooseUs;
